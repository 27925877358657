import moment from "moment";
const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

export const convertMiliToDateTime = (
  timeInMillis: any,
  lang: string = "en"
) => {
  const date = new Date(timeInMillis);
  if (lang === "th") {
    return date
      ? moment(date).add("years", 543).format(DATE_TIME_FORMAT)
      : "";
  }
  return date ? moment(date).format(DATE_TIME_FORMAT) : "";
};
const TimeUtils = {
  convertMiliToDateTime,
};

export default TimeUtils;
