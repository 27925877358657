export enum UploadModeStatus {
  SUCCESS = "success",
  ERROR = "error",
  PENDING = "pending",
}
export interface IFileInfo {
  path?: string;
  lastModified?: any;
  name?: string;
  size?: number;
  type?: string;
}
export interface IFileItemData {
  id?: string;
  name?: string;
  type?: any;
  fileData?: IFileInfo;
  key?: any;
  imageData?: any;
  status?: UploadModeStatus;
}
