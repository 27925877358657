import ClassNames from "classnames";
import React from "react";
import { Activity } from "../../../interfaces/activity";

export interface IActivityItemContentProps {
  data: Activity;
  Messages?: any;
}

const ActivityItemContent: React.FC<IActivityItemContentProps> = ({ data }) => {
  const { content, created_by, data: activityData } = data;
  const itemClass = ClassNames("text");
  let activityContent = content;
  if (activityData && activityData.comment) {
    activityContent = content.replace(":comment", activityData.comment);
  }
  if (activityData && activityData.remark) {
    activityContent = content.replace(":remark", activityData.remark);
  }
  const message = `${created_by?.name} ${activityContent}`;

  return <span className={itemClass}>{`${message}`}</span>;
};

export default ActivityItemContent;
