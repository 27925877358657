import { API } from "./API";
import { URL_ACTIVITY, URL_ACTIVITY_COMMENT } from "./URL";

export const getActivityList = (id: string, paging = {}) => {
  return API.get(`${URL_ACTIVITY}/${id}`, {
    params: { ...paging },
  });
};

export const addComment = (id: string, payload: any) => {
  return API.post(`${URL_ACTIVITY_COMMENT}/${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
