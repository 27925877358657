import axios, { AxiosRequestConfig } from "axios";
import { ADMIN_AUTH_KEY } from "../constants/common";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 30000,
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const adminInfo = JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "");
    if (!config.headers) {
      config.headers = {};
    }
    if (adminInfo.token) {
      config.headers.Authorization =  `Bearer ${adminInfo.token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error?.response?.status === 401) {
    window.location.replace('/login');
  }
  return Promise.reject(error);
});

export const API = {
  get: (url: string, config?: AxiosRequestConfig<any> | undefined) =>
    instance.get(url, config),
  post: (
    url: string,
    data: any,
    config?: AxiosRequestConfig<any> | undefined
  ) => instance.post(url, data, config),
  put: (
    url: string,
    data: any,
    config?: AxiosRequestConfig<any> | undefined
  ) => instance.post(url, data, config),
  delete: (url: string, config?: AxiosRequestConfig<any> | undefined) =>
    instance.delete(url, config),
};
