import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../../constants/common";

interface PermissibleRenderProps {
  permission: string;
}

export const isGranted = (permission: string) => {
  const adminInfo = JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "{}");
  const permissions = adminInfo?.user?.permissions ?? [];
  return permissions.includes("*") || permissions.includes(permission);
};

export const PermissibleRender: React.FC<PermissibleRenderProps> = ({
  children,
  permission,
}) => {
  const [adminInfo] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const { permissions } = adminInfo?.user ?? {};
  const isGranted =
    permissions.includes("*") || permissions.includes(permission);

  return isGranted ? <>{children}</> : null;
};

export default PermissibleRender;
