import {
  Card,
  HeaderDetail,
  HeaderDetailProps,
  Icon,
  IRowsKey,
  Modal,
  ViewRowInterchange,
} from "d-react-components";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Coupon, CouponAllStatus } from "../../interfaces/coupon";
import { getCouponDetail } from "../../network/coupon";
import CouponApprove from "./CouponApprove";
import CouponCancel from "./CouponCancel";
import CouponRedeem from "./CouponRedeem";
import CouponStatusItem, { getStatusOfCoupon } from "./CouponStatus";
import { ActivitiesView } from "../common/Activity/ActivityView";
import { isGranted } from "../common/PermissibleComponent";
import { PERM_APPROVE, PERM_CANCEL } from "../../constants/common";
import TimeUtils from "../../utils/TimeUtils";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

const CouponDetail = () => {
  const [coupon, setCoupon] = useState<Coupon>({});
  const [showApprove, setShowApprove] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const { id } = useParams<any>();
  const history = useHistory();
  const [showDetailDrawer, setShowDetailDrawer] = useState(false);

  const couponStatus = useMemo(() => getStatusOfCoupon(coupon), [coupon]);
  const isCancellable =
    [CouponAllStatus.APPROVED, CouponAllStatus.PENDING].includes(
      couponStatus?.statusText
    ) && isGranted(PERM_CANCEL);

  const loadDetail = () => {
    if (id) {
      getCouponDetail(id).then((resp) => {
        setCoupon(resp?.data?.data?.coupon ?? {});
      });
    }
  };

  useEffect(() => {
    loadDetail();
  }, []);

  const COUPON_KEYS: IRowsKey<Coupon>[] = [
    {
      id: "discount_value",
      label: "Discount Value",
      renderContent: ({ data, item }) =>
        item.discount_type === "fixed_amount" ? `฿${data}` : `${data}%`,
    },
    {
      id: "effective_date",
      label: "Effective Date",
      renderContent: ({ data }) =>
        data ? TimeUtils.convertMiliToDateTime(data) : "N/A",
    },
    {
      id: "expiry_date",
      label: "Expiry Date",
      renderContent: ({ data }) =>
        data ? TimeUtils.convertMiliToDateTime(data) : "No expiry date",
    },
    { id: "remark", label: "Remark" },
  ];

  const USAGE_KEYS: IRowsKey<Coupon>[] = [
    {
      id: "status",
      label: "Usage Status",
      renderContent: ({ data, item }) => (
        <div className="inline-block">
          <CouponStatusItem coupon={item} />
        </div>
      ),
    },
    {
      id: "used_at",
      label: "Used Date",
      renderContent: ({ data }) =>
        data ? TimeUtils.convertMiliToDateTime(data) : "N/A",
    },
    { id: "used_remark", label: "Usage Remark" },
    {
      id: "attachments",
      label: "Attachments",
      renderContent: ({ data }) =>
        data ? (
          <div className="flex flex-wrap">
            <Gallery>
              {data.map((item: any, index: number) => (
                <Item
                  key={index}
                  original={item.attachment}
                  width="1280"
                  height="720"
                >
                 {({ ref, open }) => (
                  <div
                    ref={ref as React.MutableRefObject<HTMLDivElement>}
                    onClick={open}
                    className="w-24 h-24 overflow-hidden mr-2 mb-2 cursor-pointer"
                  >
                    <img src={item.attachment} className="w-24 h-24 object-cover" />
                  </div>
                )}
                </Item>
              ))}
            </Gallery>
          </div>
        ) : (
          []
        ),
    },
  ];

  const APPROVAL_KEYS: IRowsKey<Coupon>[] = [
    {
      id: "status_by",
      label: "Approved by",
      renderContent: ({ data, item }) => data?.name,
    },
    {
      id: "status_at",
      label: "Approved at",
      renderContent: ({ data }) =>
        data ? TimeUtils.convertMiliToDateTime(data) : "N/A",
    },
    { id: "status_remark", label: "Approval Remark" },
  ];

  const listButton: HeaderDetailProps["listButton"] = useMemo(() => {
    return [
      ...(!isEmpty(coupon) &&
      couponStatus.statusText === CouponAllStatus.APPROVED
        ? [
            {
              id: "redeem",
              icon: "redeem",
              label: "Redeem",
              buttonProps: {},
              onClick: () => {
                setShowDetailDrawer(true);
              },
            },
          ]
        : []),
      ...(!isEmpty(coupon) &&
      couponStatus.statusText === CouponAllStatus.PENDING &&
      isGranted(PERM_APPROVE)
        ? [
            {
              id: "pending",
              icon: "check_circle",
              label: "Approve",
              buttonProps: {},
              onClick: () => {
                setShowApprove(true);
              },
            },
          ]
        : []),
      ...(!isEmpty(coupon) && isCancellable
        ? [
            {
              id: "cancel",
              icon: "cancel",
              label: "Cancel",
              buttonProps: {},
              onClick: () => {
                setShowCancel(true);
              },
            },
          ]
        : []),
    ];
  }, [coupon]);

  return (
    <div className="p-4">
      <span
        className="flex items-center mb-3 cursor-pointer"
        onClick={() => history.goBack()}
      >
        <Icon name="chevron_left" />
        Back
      </span>
      <HeaderDetail
        title={coupon.code}
        listButton={listButton}
        customStatus={() => <CouponStatusItem coupon={coupon} />}
      />
      <Card title={"Voucher Details"} className="mb-3">
        <ViewRowInterchange
          keyList={COUPON_KEYS}
          dataSource={coupon}
          className="text-left"
        />
      </Card>
      {!isEmpty(coupon.status_by) &&
      couponStatus.statusText !== CouponAllStatus.CANCELLED ? (
        <Card title={"Approval Details"} className="mb-3">
          <ViewRowInterchange
            keyList={APPROVAL_KEYS}
            dataSource={coupon}
            className="text-left"
          />
        </Card>
      ) : null}
      {!isEmpty(coupon.used_at) ? (
        <Card title={"Usage Details"} className="mb-3">
          <ViewRowInterchange
            keyList={USAGE_KEYS}
            dataSource={coupon}
            className="text-left"
          />
        </Card>
      ) : null}
      <ActivitiesView coupon={coupon} />
      {showApprove && (
        <Modal
          open={showApprove}
          onClose={() => {
            setShowApprove(false);
          }}
          showFooter={false}
          title={"Approve Voucher"}
          width="500px"
        >
          <CouponApprove
            ids={[coupon.id]}
            onClose={() => {
              setShowApprove(false);
              loadDetail();
            }}
          />
        </Modal>
      )}
      {showCancel && (
        <Modal
          open={showCancel}
          onClose={() => {
            setShowCancel(false);
          }}
          showFooter={false}
          title={"Cancel Voucher"}
          width="500px"
        >
          <CouponCancel
            ids={[coupon.id]}
            onClose={() => {
              setShowCancel(false);
              loadDetail();
            }}
          />
        </Modal>
      )}
      {showDetailDrawer && (
        <Modal
          open={showDetailDrawer}
          onClose={() => {
            setShowDetailDrawer(false);
          }}
          showFooter={false}
          title={"Redeem Voucher"}
          width="500px"
        >
          <CouponRedeem
            onClose={() => {
              setShowDetailDrawer(false);
              loadDetail();
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default CouponDetail;
