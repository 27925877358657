import { API } from "./API";
import {
  URL_APPROVE_COUPON,
  URL_CANCEL_COUPON,
  URL_COUPON,
  URL_USE_COUPON,
  URL_ATTACHMENT_COUPON,
} from "./URL";

export const getCouponList = (params: any) => {
  return API.get(URL_COUPON, { params });
};

export const createCoupon = (payload: any) => {
  return API.post(URL_COUPON, payload);
};

export const getCouponDetail = (id: string) => {
  return API.get(`${URL_COUPON}/${id}`);
};

export const redeemCoupon = (id: string, payload: any) => {
  return API.post(`${URL_USE_COUPON}/${id}`, { ...payload, _method: "PUT" });
};

export const approveCoupon = (payload: any) => {
  return API.post(`${URL_APPROVE_COUPON}`, payload);
};

export const cancelCoupon = (payload: any) => {
  return API.post(`${URL_CANCEL_COUPON}`, payload);
};

export const attachmentCoupon = (id: string, payload: any) => {
  return API.post(`${URL_ATTACHMENT_COUPON}/${id}`, payload);
};
