import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, InputText, Notifications } from "d-react-components";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../constants/common";
import { adminLogin } from "../../network/auth";
import { useHistory } from "react-router";

interface LoginProps {
  [key: string]: any;
}

interface LoginForm {
  email?: string;
  password?: string;
}

export const Login: React.FC<LoginProps> = () => {
  const [, setAdminInfo] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("This field is required"),
    password: Yup.string().required("This field is required"),
  });
  const loginForm = useFormik<LoginForm>({
    initialValues: {},
    validationSchema: LoginSchema,
    onSubmit: (e) => {
      const { email, password } = values;
      if (email && password) {
        adminLogin(email, password).then(
          (resp) => {
            setAdminInfo(resp?.data?.data);
            Notifications.showSuccess(resp?.data?.message);
            history.push('/');
          },
          (error) => {
            Notifications.showError(error);
          }
        );
      }
    },
  });
  const { values, errors, handleSubmit, handleChange } = loginForm;

  return (
    <div className="bg-gray-100">
      <div className="flex items-center justify-center min-h-screen">
        <div className="max-w-[500px] w-full m-auto bg-white p-6 text-left">
          <>
            <InputText
              name="email"
              label={"Email"}
              placeholder={"Email"}
              value={values.email}
              error={errors.email}
              onChange={handleChange}
              classNameInput="w-full"
              className="mb-3"
            />
            <InputText
              name="password"
              label={"Password"}
              placeholder={"Password"}
              value={values.password}
              error={errors.password}
              onChange={handleChange}
              classNameInput="w-full"
              className="mb-3"
              type="password"
            />
          </>
          <Button
            color="primary"
            variant="standard"
            onClick={() => handleSubmit()}
            title={`login`}
            className="w-100 mt-4"
            type="button"
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
