import React, { useEffect, useRef } from 'react';
import { Switch, Route } from "react-router-dom";
import "./styles/tailwind.css";
import './App.css';
import './styles/styles.scss';
import Coupons from './components/coupons/Coupons';
import CouponDetail from './components/coupons/CouponDetail';
import Login from './components/login/Login';
import ProtectedRoute from './components/common/ProtectedRoute';
import { DialogManager, DialogComponent } from "d-react-components";

function App() {
  const dialogRef = useRef();
  useEffect(() => {
    if (dialogRef.current) {
      DialogManager.initialDialog(dialogRef.current);
    }
  }, [dialogRef]);

  return (
    <div className="bg-gray-100 min-h-screen">
      <Switch>
        <ProtectedRoute exact path="/" component={Coupons} />
        <Route exact path="/login" component={Login} />
        <ProtectedRoute path="/coupon/:id" component={CouponDetail} />
      </Switch>
        <DialogComponent ref={dialogRef} />
    </div>
  );
}

export default App;
