import { useFormik } from "formik";
import { Button, DateInput, InputText, Notifications } from "d-react-components";
import { attachmentCoupon, redeemCoupon } from "../../network/coupon";
import { useParams } from "react-router";
import ButtonFileUpload from "../common/Upload/ButtonFileUpload";
import { ElementRef, useRef, useState } from "react";
import { IFileItemData } from "../../interfaces/file";
import moment, { Moment } from "moment";

interface CouponRedeemInput {
  used_remark?: string;
  used_at?: Moment;
}
const runSerial = (promises: any[]) => {
  let result = Promise.resolve();
  promises.forEach((promise) => {
    result = result.then(() => typeof promise === "function" && promise());
  });
  return result;
};

const CouponRedeem = ({ onClose }: any) => {
  const { id } = useParams<any>();
  const buttonUploadRef = useRef<ElementRef<typeof ButtonFileUpload>>(null);
  const [attachments, setAttachments] = useState<IFileItemData[]>([]);
  console.log(attachments);
  const couponForm = useFormik<CouponRedeemInput>({
    initialValues: {
      used_at: moment()
    },
    onSubmit: () => {
      const redeemPromises = [];
      const files = attachments.map((item) => {
        return item.fileData;
      });
      if (files.length) {
        files.forEach((file) => {
          const formData = new FormData();
          formData.append("file", file as any);
          redeemPromises.push(() => attachmentCoupon(id, formData));
        });
      }
      redeemPromises.push(() =>
        redeemCoupon(id, {
          used_remark: values?.used_remark ?? "",
          used_at: values?.used_at,
        })
      );
      runSerial(redeemPromises).then(
        (resp) => {
          Notifications.showSuccess("Redeem voucher successfully");
          onClose && onClose();
        },
        (err) => {
          Notifications.showError("Voucher Invalid");
        }
      );
    },
  });
  const { values, errors, setFieldValue, handleSubmit } = couponForm;

  return (
    <div>
      <div className="mb-3">
        <DateInput
          label="Used At"
          value={values?.used_at}
          error={errors?.used_at}
          onChange={(val) => setFieldValue("used_at", val)}
          allowClear={false}
          showTime
          format="DD/MM/YYYY HH:mm"
        />
      </div>
      <div className="mb-3">
        <InputText
          multiple
          label="Remark"
          value={values?.used_remark}
          error={errors?.used_remark}
          onChange={(val) => setFieldValue("used_remark", val?.target?.value)}
        />
      </div>
      <ButtonFileUpload
        onChange={setAttachments as any}
        maxFiles={5}
        className="mb-3"
        ref={buttonUploadRef}
        mode="multi-upload"
        buttonText="Add Attchment"
        inputParam={
          {
            accept: "image/jpg,image/jpeg,image/png",
          } as any
        }
        fileListClassName="order-2 flex-wrap"
        classNameButton="mb-3"
      />
      <div className="w-100 flex justify-content-end">
        <Button onClick={() => handleSubmit()}>Save</Button>
      </div>
    </div>
  );
};

export default CouponRedeem;
