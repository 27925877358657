import { Button, DateInput, Select } from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { CouponExportInput, CouponStatuses } from "../../interfaces/coupon";
import { getCouponList } from "../../network/coupon";
import FileUtils from "../../utils/FileUtils";
import ModalProgress from "../common/ModalProgress";
import { getStatusOfCoupon } from "./CouponStatus";
import TimeUtils from "../../utils/TimeUtils";

interface IProgressModal {
  open: boolean;
  percentage?: number;
}

const FormSchema = Yup.object().shape({
  status: Yup.mixed(),
  effective: Yup.mixed(),
  expiry: Yup.mixed(),
});

const CouponExport = ({
  mode = "export",
  onClose,
  filterData,
}: {
  mode?: "filter" | "export";
  onClose?: any;
  filterData?: any;
}) => {
  const [openProgressModal, setOpenProgressModal] = useState<IProgressModal>({
    open: false,
  });

  const handleExport = async () => {
    const payload = getPayload();
    let remainPage = true;
    let pageIndex = 1;
    let totalData: Array<any> = [];
    while (remainPage && openProgressModal.open) {
      const res = await getCouponList({
        ...payload,
        page: pageIndex,
        per_page: 100,
      });
      const pagination = res?.data?.data?.pagination ?? {};
      const { total, currentPage } = pagination;
      setOpenProgressModal((prev) => ({
        ...prev,
        percentage: (currentPage / total) * 100,
      }));
      pageIndex += 1;
      const data = res?.data?.data?.coupon ?? [];
      totalData = [...totalData, ...data];
      remainPage = pageIndex <= total;
    }
    setOpenProgressModal({ open: false });
    FileUtils.exportToCSV(
      totalData.map((item) => ({
        No: item.id,
        Code: item.code,
        "Discount Value":
          item.discount_type === "fixed_amount"
            ? `฿${item.discount_value}`
            : `${item.discount_value}%`,
        "Effective Date": TimeUtils.convertMiliToDateTime(item.effective_date),
        "Expiry Date": item.expiry_date
          ? TimeUtils.convertMiliToDateTime(item.expiry_date)
          : "No Expiry Date",
        "Issued Remark": item?.remark ?? "",
        "Created By": item?.created_by?.name,
        "Usage Status": getStatusOfCoupon(item)?.statusText,
        "Used Date": item.used_at
          ? TimeUtils.convertMiliToDateTime(item.used_at)
          : "N/A",
        "Used Remark": item?.used_remark ?? "",
      })),
      `${TimeUtils.convertMiliToDateTime(new Date())}`
    );
  };

  const getPayload = () => {
    return {
      ...(values.effective
        ? {
            "effective[from]": moment(values?.effective?.[0]).toISOString(),
            "effective[to]": moment(values?.effective?.[1]).toISOString(),
          }
        : {}),
      ...(values.expiry
        ? {
            "expiry[from]": moment(values?.expiry?.[0]).toISOString(),
            "expiry[to]": moment(values?.expiry?.[1]).toISOString(),
          }
        : {}),
      // ...(values.createdDate
      //   ? {
      //       "createdDate[from]": moment(values?.createdDate?.[0]).toISOString(),
      //       "createdDate[to]": moment(values?.createdDate?.[1]).toISOString(),
      //     }
      //   : {}),
      status: values?.status,
    };
  };

  useEffect(() => {
    if (openProgressModal.open) {
      handleExport();
    }
  }, [openProgressModal.open]);

  const couponForm = useFormik<CouponExportInput>({
    initialValues: {},
    onSubmit: async () => {
      const payload = getPayload();
      if (mode === "filter") {
        onClose && onClose(payload);
      } else {
        setOpenProgressModal({
          open: true,
          percentage: 0,
        });
      }
    },
    validationSchema: FormSchema,
  });
  const { values, errors, setFieldValue, setValues, handleSubmit } = couponForm;

  useEffect(() => {
    if (filterData) {
      setValues({
        ...(filterData?.["effective[from]"]
          ? {
              effective: [
                moment(filterData?.["effective[from]"]),
                moment(filterData?.["effective[to]"]),
              ],
            }
          : {}),
        ...(filterData?.["expiry[from]"]
          ? {
              expiry: [
                moment(filterData?.["expiry[from]"]),
                moment(filterData?.["expiry[to]"]),
              ],
            }
          : {}),
        // ...(filterData?.["createdDate[from]"]
        //   ? {
        //       createdDate: [
        //         moment(filterData?.["createdDate[from]"]),
        //         moment(filterData?.["createdDate[to]"]),
        //       ],
        //     }
        //   : {}),
        ...(filterData?.status
          ? {
              status: filterData?.status,
            }
          : {}),
      });
    }
  }, []);

  return (
    <div>
      <div className="mb-3">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 md:col-span-1">
            <DateInput
              isRangePicker
              value={values?.effective}
              error={errors?.effective as string}
              label="Effective Date"
              onChange={(val) => setFieldValue("effective", val)}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DateInput
              isRangePicker
              value={values?.expiry}
              error={errors?.expiry as string}
              label="Expiry Date"
              onChange={(val) => setFieldValue("expiry", val)}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="grid grid-cols-2 gap-4">
          {/* <div className="col-span-2 md:col-span-1">
            <DateInput
              isRangePicker
              value={values?.createdDate}
              error={errors?.createdDate as string}
              label="Created Date"
              onChange={(val) => setFieldValue("createdDate", val)}
            />
          </div> */}
          <div className="col-span-2 md:col-span-2">
            <Select
              label="Status"
              dataSource={CouponStatuses}
              value={values?.status}
              error={errors?.status}
              onChange={(val) => setFieldValue("status", val)}
            />
          </div>
        </div>
      </div>
      <div className="w-100 flex justify-content-end">
        <Button onClick={() => handleSubmit()}>
          {mode === "export" ? "Export" : "Save"}
        </Button>
      </div>
      {openProgressModal.open && (
        <ModalProgress
          open={openProgressModal.open}
          onClose={() => setOpenProgressModal({ open: false })}
          progressPercentage={openProgressModal.percentage}
        />
      )}
    </div>
  );
};

export default CouponExport;
