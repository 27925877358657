import React, { useMemo } from "react";
import { Coupon, CouponAllStatus, CouponStatus } from "../../interfaces/coupon";
import StatusItem from "../common/StatusItem/StatusItem";

export const statusItems = {
  pending: {
    statusText: CouponAllStatus.PENDING,
    className: "bg-amber-100 ml-2",
    dotClassName: "bg-amber-500",
    textClassName: "text-amber-500 font-normal",
  },
  cancelled: {
    statusText: CouponAllStatus.CANCELLED,
    className: "bg-gray-100 ml-2",
    dotClassName: "bg-gray-500",
    textClassName: "text-gray-500 font-normal",
  },
  approved: {
    statusText: CouponAllStatus.APPROVED,
    className: "bg-green-100 ml-2",
    dotClassName: "bg-green-500",
    textClassName: "text-green-500 font-normal",
  },
  used: {
    statusText: CouponAllStatus.REDEEMED,
    className: "bg-blue-100 ml-2",
    dotClassName: "bg-blue-500",
    textClassName: "text-blue-500 font-normal",
  },
  expired: {
    statusText: CouponAllStatus.EXPIRED,
    className: "bg-red-100 ml-2",
    dotClassName: "bg-red-500",
    textClassName: "text-red-500 font-normal",
  },
};

export const getStatusOfCoupon = (coupon: Coupon) => {
  if (coupon.status === CouponStatus.APPROVED) {
    if (coupon.used_at) {
      return statusItems.used;
    } else if (
      coupon.expiry_date &&
      new Date(coupon.expiry_date) < new Date()
    ) {
      return statusItems.expired;
    } else {
      return statusItems.approved;
    }
  } else if (coupon.status === CouponStatus.PENDING) {
    return statusItems.pending;
  } else if (coupon.status === CouponStatus.CANCELLED) {
    return statusItems.cancelled;
  }
  return statusItems.pending;
};

const CouponStatusItem = ({ coupon }: { coupon: Coupon }) => {
  const status = useMemo(() => {
    return getStatusOfCoupon(coupon);
  }, [coupon]);

  return <StatusItem {...status} />;
};

export default CouponStatusItem;
