import {
  AwesomeTableComponent,
  Button,
  Card,
  IColumnsProps,
  Modal,
} from "d-react-components";
import { forEach } from "lodash";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { PERM_APPROVE } from "../../constants/common";
import { Coupon, CouponAllStatus, CouponStatus } from "../../interfaces/coupon";
import { getCouponList } from "../../network/coupon";
import TimeUtils from "../../utils/TimeUtils";
import { HeaderTableFilter } from "../common/HeaderTable";
import PermissibleRender, { isGranted } from "../common/PermissibleComponent";
import CouponApprove from "./CouponApprove";
import CouponCreate from "./CouponCreate";
import CouponExport from "./CouponExport";
import CouponStatusItem, { getStatusOfCoupon } from "./CouponStatus";

const Coupons = () => {
  const tableRef = useRef<any>(null);
  const [showDetailDrawer, setShowDetailDrawer] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showExport, setShowExport] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [filterData, setFilterData] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [selectedPending, setSelectedPending] = useState<
    Array<number | string>
  >([]);
  const [total, setTotal] = useState(0);

  const tableColumns: IColumnsProps = [
    {
      title: "No",
      dataIndex: "id",
      align: "left",
      width: 50,
    },
    {
      title: "Voucher Code",
      dataIndex: "code",
      align: "left",
      width: 100,
      render: (data, item, index) => (
        <Link to={`/coupon/${item?.id}`}>{data}</Link>
      ),
    },
    {
      dataIndex: "discount_value",
      title: "Discount Value",
      align: "left",
      width: 80,
      render: (data, item) =>
        item.discount_type === "fixed_amount" ? `฿${data}` : `${data}%`,
    },
    {
      title: "Effective Date",
      dataIndex: "effective_date",
      align: "left",
      width: 60,
      render: (data, item, index) => TimeUtils.convertMiliToDateTime(data),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      align: "left",
      width: 60,
      render: (data, item, index) =>
        data ? TimeUtils.convertMiliToDateTime(data) : "No Expiry Date",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      align: "left",
      width: 80,
      render: (data, item, index) => data?.name,
    },
    {
      title: "Usage Status",
      dataIndex: "status",
      align: "left",
      width: 60,
      render: (data: CouponStatus, item, index) => (
        <div className="inline-block">
          <CouponStatusItem coupon={item} />
        </div>
      ),
    },
    {
      title: "Used Date",
      dataIndex: "used_at",
      align: "left",
      width: 60,
      render: (data, item, index) =>
        data ? TimeUtils.convertMiliToDateTime(data) : "N/A",
    },
  ];

  const refreshTable = () => {
    return tableRef.current && tableRef.current.refresh();
  };

  const loadTableData = (paging: any) => {
    return getCouponList({
      page: paging.pageIndex,
      per_page: paging.pageSize,
      ...filterData,
      ...(searchText ? { search: searchText } : {}),
    }).then((resp: any) => {
      setTotal(resp?.data?.data?.pagination?.items);
      return resp;
    });
  };

  useUpdateEffect(() => {
    refreshTable();
  }, [filterData]);

  const approveAll = () => {
    setShowApprove(true);
  };

  const getPendingRows = (rows: Coupon[]) => {
    const pendingRows: number[] = [];
    forEach(rows, (row) => {
      const status = getStatusOfCoupon(row);
      if (status && status.statusText === CouponAllStatus.PENDING) {
        pendingRows.push(row.id as any);
      }
    });
    setSelectedPending(pendingRows);
  };

  return (
    <div className="p-4">
      <Card>
        <HeaderTableFilter
          label="Vouchers"
          className="mb-3"
          onClickNew={() => setShowDetailDrawer(true)}
          onClickExport={() => setShowExport(true)}
          onClickFilter={() => setShowFilter(true)}
          searchValue={searchText}
          onSubmitSearch={refreshTable}
          onChangeText={(val: any) => setSearchText(val?.target?.value)}
        />
        {/* @ts-ignore */}
        <AwesomeTableComponent
          source={loadTableData}
          transformer={(res) => {
            return res?.data?.data?.coupon ?? [];
          }}
          columns={tableColumns}
          ref={tableRef as any}
          {...(isGranted(PERM_APPROVE)
            ? { 
                selectingRows: selectedRows,
                rowSelection: {
                  onChange: (value, selectedRows) => {
                    setSelectedRows(value);
                    getPendingRows(selectedRows);
                  },
                  selectedRowKeys: selectedRows,
                  preserveSelectedRowKeys: true,
                },
              }
            : {})}
          onSelectionView={(selectingRows) => {
            const length = selectingRows?.length ?? "N/A";
            return (
              <PermissibleRender permission={PERM_APPROVE}>
                <Button
                  variant="outline"
                  content={`Bulk Approve (${length})`}
                  onClick={() => approveAll()}
                />
              </PermissibleRender>
            );
          }}
          pagination={{
            showSizeChanger: true,
            total,
            showQuickJumper: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
          }}
        />
        {showDetailDrawer && (
          <Modal
            open={showDetailDrawer}
            onClose={() => {
              setShowDetailDrawer(false);
            }}
            showFooter={false}
            title={"Create Voucher"}
            width="800px"
            maskClosable={false}
          >
            <CouponCreate
              onClose={() => {
                refreshTable();
                setShowDetailDrawer(false);
              }}
            />
          </Modal>
        )}
        {showExport && (
          <Modal
            open={showExport}
            onClose={() => {
              setShowExport(false);
            }}
            showFooter={false}
            title={"Export Vouchers"}
            width="600px"
            maskClosable={false}
          >
            <CouponExport
              mode="export"
              onClose={() => {
                setShowExport(false);
              }}
            />
          </Modal>
        )}
        {showFilter && (
          <Modal
            open={showFilter}
            onClose={() => {
              setShowFilter(false);
            }}
            showFooter={false}
            title={"Filter Vouchers"}
            width="600px"
            maskClosable={false}
          >
            <CouponExport
              mode="filter"
              filterData={filterData}
              onClose={(payload: any) => {
                setShowFilter(false);
                setFilterData(payload);
              }}
            />
          </Modal>
        )}
        {showApprove && (
          <Modal
            open={showApprove}
            onClose={() => {
              setShowApprove(false);
            }}
            showFooter={false}
            title={"Approve Voucher"}
            width="500px"
          >
            <CouponApprove
              ids={selectedPending}
              onClose={() => {
                setShowApprove(false);
                refreshTable();
              }}
            />
          </Modal>
        )}
      </Card>
    </div>
  );
};

export default Coupons;
