import React from "react";
import { Modal, ModalProps } from "d-react-components";
import { Progress } from "antd";

export interface IModalProgressProps extends Omit<ModalProps, "children"> {
  progressPercentage?: number;
}

const ModalProgress: React.FC<IModalProgressProps> = ({
  open,
  onClose,
  progressPercentage,
  ...rest
}) => {
  const renderContentModal = () => {
    return (
      <div>
        <Progress percent={progressPercentage} />
      </div>
    );
  };
  return (
    <Modal
      showHeader={false}
      open={open}
      onClose={onClose}
      size="small"
      maskClosable={false}
      closable={false}
      showFooter={false}
      {...rest}
    >
      {renderContentModal()}
    </Modal>
  );
};

export default ModalProgress;
