import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import classname from "classnames";
import React, { CSSProperties } from "react";
import { ViewTextError } from "d-react-components";
import "./CurrencyInput.scss";

interface Props extends Omit<CurrencyInputProps, 'onChange'> {
    error?: string;
    label?: string;
    onChange?: (value: string | undefined) => void;
    style?: CSSProperties;
    styleLabel?: CSSProperties;
    className?: string;
    disabled?: boolean;
}

const Input = ({
    value,
    label,
    error,
    onChange,
    style = {},
    styleLabel = {},
    className,
    disabled = false,
    ...props
}: Props) => {
    const container = classname("d-input-text__container", className);
    const labelClass = classname("text-label");
    return (
        <div className={container} style={style}>
            {label && (
                <label className={labelClass} style={styleLabel}>
                    <span>{label}</span>
                </label>
            )}
            <div className="d-input-text__input-container d-input-text__input-container-outline">
                <CurrencyInput
                    value={value || ""}
                    decimalsLimit={2}
                    onValueChange={(value) => onChange && onChange(value)}
                    disabled={disabled}
                    className="text-x-small d-input-text__input"
                    {...props}
                />
            </div>
            <ViewTextError error={error} />
        </div>
    );
};

export default Input;
