import React from "react";
import {
  Button,
  DateInput,
  DialogManager,
  InputText,
  Notifications,
  Select,
} from "d-react-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import CurrencyInput from "../common/CurrencyInput/CurrencyInput";
import { CouponInput, CouponStatus } from "../../interfaces/coupon";
import { createCoupon } from "../../network/coupon";
import moment from "moment";
import TimeUtils from "../../utils/TimeUtils";

const FormSchema = Yup.object().shape({
  discount_value: Yup.number().nullable().required("This field is required"),
  remark: Yup.string(),
  effective_date: Yup.date().nullable().required("This field is required"),
  quantity: Yup.number()
    .nullable()
    .max(1000)
    .required("This field is required"),
  prefix: Yup.string().nullable().max(3),
  expiry_date: Yup.date()
    .nullable()
    .default(null)
    .when(
      "effective_date",
      (effectiveDate, yup) =>
        effectiveDate &&
        yup.min(effectiveDate, "Expiry date cannot be before effective date")
    ),
});

const CouponCreate = ({ onClose }: any) => {
  const couponForm = useFormik<CouponInput>({
    initialValues: {
      discount_type: "fixed_amount",
      quantity: 1,
    },
    onSubmit: () => {
      if (values.quantity && values.quantity > 1) {
        DialogManager.showConfirm(
          "Confirmation",
          <>
            <p className="text-sm">
              You’re creating the vouchers in bulk with the following
              information. Please check and re-confirm your action.
              <ul className="mt-3 list-disc">
                <li>Quantity: {values.quantity}</li>
                {values?.prefix && <li>Prefix: {values?.prefix}</li>}
                <li>
                  Discount type:{" "}
                  {values.discount_type === "fixed_amount"
                    ? "Fixed Amount"
                    : "Percentage"}
                </li>
                <li>
                  Discount value:{" "}
                  {values.discount_type === "fixed_amount"
                    ? `฿${values.discount_value}`
                    : `${values.discount_value}%`}
                </li>
                <li>
                  Effective date:{" "}
                  {TimeUtils.convertMiliToDateTime(values.effective_date)}
                </li>
                <li>
                  Expiry date:{" "}
                  {values.expiry_date
                    ? TimeUtils.convertMiliToDateTime(values.expiry_date)
                    : "No expiry date"}
                </li>
                <li>Remark: {values?.remark}</li>
              </ul>
            </p>
          </>,
          () => handleCreateCoupon()
        );
      } else {
        handleCreateCoupon();
      }
    },
    validationSchema: FormSchema,
  });
  const { values, errors, setFieldValue, handleSubmit } = couponForm;

  const handleCreateCoupon = () => {
    createCoupon({
      effective_date: moment
        .utc(values.effective_date)
        .format("YYYY-MM-DD HH:mm:ss"),
      expiry_date: values.expiry_date
        ? moment.utc(values.expiry_date).format("YYYY-MM-DD HH:mm:ss")
        : null,
      status: CouponStatus.PENDING,
      discount_type: values?.discount_type,
      discount_value: values?.discount_value,
      quantity: values?.quantity,
      ...(values?.prefix ? { prefix: values?.prefix } : {}),
      remark: values?.remark,
    }).then(
      (resp) => {
        Notifications.showSuccess("Coupon created successfully");
        onClose && onClose();
      },
      (error) => {
        Notifications.showError(error);
      }
    );
  };

  return (
    <div>
      <div className="mb-3">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 md:col-span-1">
            <Select
              label={"Type"}
              value={values?.discount_type}
              error={errors?.discount_type}
              onChange={(val) => {
                setFieldValue("discount_type", val);
                setFieldValue("discount_value", null);
              }}
              dataSource={[
                { id: "fixed_amount", label: "Fixed Amount" },
                { id: "percentage", label: "Percentage" },
              ]}
            />
          </div>

          <div className="col-span-2 md:col-span-1">
            <CurrencyInput
              value={values?.discount_value || ""}
              error={errors?.discount_value}
              onChange={(val) => setFieldValue("discount_value", val)}
              label="Discount value"
              placeholder="Discount value"
              prefix={values.discount_type === "fixed_amount" ? "฿" : ""}
              suffix={values.discount_type === "fixed_amount" ? "" : "%"}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 md:col-span-1">
            <DateInput
              label="Effective Date"
              value={values?.effective_date}
              error={errors?.effective_date}
              onChange={(val) => setFieldValue("effective_date", val)}
              format="DD/MM/YYYY HH:mm"
              showTime
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DateInput
              label="Expiry Date"
              value={values?.expiry_date}
              error={errors?.expiry_date}
              onChange={(val) => setFieldValue("expiry_date", val)}
              format="DD/MM/YYYY HH:mm"
              showTime
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 md:col-span-1">
            <InputText
              value={values?.quantity || ""}
              error={errors?.quantity}
              onChange={(val) => setFieldValue("quantity", val?.target?.value)}
              label="Quantity"
              placeholder="Input quantity"
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <InputText
              value={values?.prefix || ""}
              error={errors?.prefix}
              onChange={(val) => setFieldValue("prefix", val?.target?.value)}
              label="Prefix"
              placeholder="Input prefix"
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <InputText
          multiple
          label="Remark"
          value={values?.remark}
          error={errors?.remark}
          onChange={(val) => setFieldValue("remark", val?.target?.value)}
        />
      </div>
      <div className="w-100 flex justify-content-end">
        <Button onClick={() => handleSubmit()}>Save</Button>
      </div>
    </div>
  );
};

export default CouponCreate;
