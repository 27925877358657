/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import ClassNames from "classnames";
import {
  AwesomeListComponent,
  AwesomeListComponentProps,
  Button,
  Card,
  Icon,
  InputText,
  Loading,
  TimeUtils,
  RenderPreviewFile,
  StringUtils,
} from "d-react-components";
import Carousel, { Modal, ModalGateway } from "react-images";
import { isEmpty, throttle } from "lodash";
import React, { ElementRef, forwardRef, useEffect, useRef, useState } from "react";
import ActivityItemContent from "./ActivityItemContent";
import { addComment, getActivityList } from "../../../network/activity";
import { Activity } from "../../../interfaces/activity";
import { IFileItemData } from "../../../interfaces/file";
import ButtonFileUpload from "../Upload/ButtonFileUpload";
import { Coupon } from "../../../interfaces/coupon";

export interface IActivityItemProps {
  index: any;
  data: Activity;
  onClickImage: (index: number) => void;
}
export interface IActivitiesViewProps
  extends Partial<AwesomeListComponentProps> {
  onClickSendComment?: (props: any) => any;
  coupon: Coupon;
}
export interface IActivitiesViewMethod {
  refresh: () => any;
}

export const ActivityItem: React.FC<IActivityItemProps> = ({
  index,
  data,
  onClickImage,
}) => {
  const { created, attachment } = data;
  const wrapperClass = ClassNames("d-flex py-4", {
    "border-top": index !== 0,
  });

  const fileExtension = attachment
    ? StringUtils.getExtensionFromFilename(attachment)?.toLowerCase()
    : "";

  return (
    <div className={wrapperClass}>
      <div className="">
        <div className="">
          <div className="text-x-small">{` ${TimeUtils.convertMiliToDateTime(
            created
          )}`}</div>
          <p className="mb-0">
            <ActivityItemContent data={data} />
          </p>
        </div>
        {attachment && (
          <RenderPreviewFile
            alt=""
            item={attachment as any}
            src={attachment}
            size="x-large"
            hasLightBox={false}
            removable={false}
            onViewImage={() => onClickImage && onClickImage(0)}
            extension={fileExtension}
          />
        )}
      </div>
    </div>
  );
};

export const ActivitiesView: React.ForwardRefRenderFunction<
  IActivitiesViewMethod,
  IActivitiesViewProps
> = ({ className = "", coupon }, ref) => {
  const [commentText, setCommentText] = useState("");
  const [loadingSend, setLoadingSend] = useState(false);
  const [attachments, setAttachments] = useState<IFileItemData[]>([]);
  const buttonUploadRef = useRef<ElementRef<typeof ButtonFileUpload>>(null);
  const [imageToView, setImageToView] = useState<{
    files: string[];
    index: number;
  }>({ files: [], index: 0 });
  const { id: refId } = coupon;

  const activityList = useRef<ElementRef<typeof AwesomeListComponent>>(null);

  const refreshActivity = () => {
    return activityList.current && activityList.current.refresh();
  };

  const renderItem = (activity: Activity, index: number) => {
    return (
      <ActivityItem
        data={activity}
        index={index}
        onClickImage={(imgIndex) => {
          setImageToView({
            files: [activity?.attachment ?? ""],
            index: imgIndex,
          });
        }}
      />
    );
  };

  const onClickSend = throttle(async () => {
    if (loadingSend || !refId) {
      return;
    }
    setLoadingSend(true);
    const formData = new FormData();
    formData.append("comment", commentText);
    if (attachments.length) {
      formData.append("file", attachments?.[0]?.fileData as any);
    }
    await addComment(refId, formData);
    setLoadingSend(false);
    setCommentText("");
    setAttachments([]);
    refreshActivity();
    buttonUploadRef.current && buttonUploadRef.current.refresh();
  }, 1000);

  const renderButtonRows = () => {
    return (
      <div className="flex items-end justify-end mt-3">
        {renderAttachButton()}
        <Button
          onClick={onClickSend}
          color="primary"
          disabled={isEmpty(commentText)}
        >
          {loadingSend ? (
            <Loading size="small" />
          ) : (
            <React.Fragment>
              <Icon name="send" className="mr-2" />
              Send
            </React.Fragment>
          )}
        </Button>
      </div>
    );
  };

  const renderAttachButton = () => {
    return (
      <ButtonFileUpload
        onChange={setAttachments as any}
        maxFiles={5}
        className="d-flex justify-end items-end mr-3"
        ref={buttonUploadRef}
        mode="single-upload"
        classNameButton="mr-3"
        inputParam={
          {
            accept: "image/jpg,image/jpeg,image/png",
          } as any
        }
      />
    );
  };

  useEffect(() => {
    refreshActivity();
  }, [coupon]);

  return (
    <Card className={`my-3 ${className}`} title={"Activities"}>
      <div className="my-4">
        <div className="w-100 d-flex flex-center-start">
          <div className="w-100">
            <InputText
              placeholder={"Leave a comment"}
              multiple
              value={commentText}
              onChange={(event) => setCommentText(event.target.value)}
              className="w-100"
            />
            {renderButtonRows()}
          </div>
        </div>
      </div>
      <ModalGateway>
        {imageToView.files && imageToView.files.length > 0 ? (
          <Modal onClose={() => setImageToView({ files: [], index: 0 })}>
            <Carousel
              views={imageToView.files.map((file) => ({
                src: file,
                caption: "",
                source: file,
              }))}
              currentIndex={imageToView.index}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <AwesomeListComponent
        isPaging
        className="show-scroll-bar"
        ref={activityList}
        source={(paging) => {
          if (!refId) {
            return Promise.resolve();
          }
          return getActivityList(refId, {
            page: paging.pageIndex,
            size: paging.pageSize,
          });
        }}
        pagingProps={{ pageSize: 10, pageIndex: 1 }}
        transformer={(res) => {
          const data = res?.data?.data?.activity ?? [];
          return data;
        }}
        renderItem={(item, index) => renderItem(item, index)}
        styleContainer={{ overflowY: "auto", maxHeight: "500px" }}
      />
    </Card>
  );
};

export default forwardRef(ActivitiesView);
