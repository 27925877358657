import { useFormik } from "formik";
import { Button, InputText, Notifications } from "d-react-components";
import { approveCoupon } from "../../network/coupon";

interface CouponApproveInput {
  status_remark?: string;
}

const CouponApprove = ({ onClose, ids }: any) => {

  const couponForm = useFormik<CouponApproveInput>({
    initialValues: {},
    onSubmit: () => {
      approveCoupon({
        'id': ids,
        status_remark: values?.status_remark ?? "",
      }).then(
        (resp) => {
          Notifications.showSuccess("Approve vouchers successfully");
          onClose && onClose();
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    },
  });
  const { values, errors, setFieldValue, handleSubmit } = couponForm;

  return (
    <div>
      <div className="mb-3">
        <InputText
          multiple
          label="Remark"
          value={values?.status_remark}
          error={errors?.status_remark}
          onChange={(val) => setFieldValue("status_remark", val?.target?.value)}
        />
      </div>
      <div className="w-100 flex justify-content-end">
        <Button onClick={() => handleSubmit()}>Save</Button>
      </div>
    </div>
  );
};

export default CouponApprove;
