import { Moment } from "moment";

export interface Coupon {
  id?: string;
  code?: string;
  discount_value?: number;
  effective_date?: string;
  expiry_date?: string;
  remark?: string;
  discount_type?: "fixed" | "percentage";
  status?: CouponStatus;
  used_at?: string;
  used_remark?: string;
  status_at?: string;
  status_remark?: string;
  created_by?: {
    id: string;
    name: string;
  };
  status_by?: {
    id: string;
    name: string;
  };
  attachments?: { attachment: string }[];
}

export enum CouponStatus {
  PENDING = 0,
  APPROVED,
  CANCELLED,
}

export enum CouponAllStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  REDEEMED = "REDEEMED",
}

export interface CouponInput {
  discount_type?: "fixed_amount" | "percentage";
  discount_value?: number;
  effective_date?: Moment;
  expiry_date?: Moment;
  remark?: string;
  quantity?: number;
  prefix?: number;
}

export const CouponStatuses = [
  {
    id: "used",
    label: "Used",
  },
  {
    id: "available",
    label: "Available",
  },
  {
    id: "expired",
    label: "Expired",
  },
];

export interface CouponExportInput {
  effective?: any;
  expiry?: any;
  status?: string[];
  search?: string;
}
