import classNames from "classnames";
import {
  Icon,
  ObjectUtils,
  StringUtils,
  useDeepEffect,
  useFirstTime,
  Notifications,
  Button,
} from "d-react-components";
import { find, includes, replace, some, split } from "lodash";
import { getType } from "mime";
import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { UploadModeStatus, IFileItemData } from "../../../interfaces/file";
import "./ButtonFileUpload.scss";

const IMAGE = {
  extension: ["jpeg", "bmp", "png", "jpg", "heic"],
  iconFile: "",
};
export const IMAGE_FORMAT = "image/x-png,image/jpeg,image/heic,image/png";
const FILE_TYPE = [
  { extension: ["docx", "doc", "pptx", "pps"], iconFile: "" },
  { extension: ["xls", "csv", "xlsx"], iconFile: "" },
  { extension: ["pdf"], iconFile: "" },
];

/**
 * depend on extension that get from fileName =>
 * if file is an image => return fileData to display in preview
 * if file is an pdf/doc/excel => return local image file to display in preview
 * @param {*} fileName
 * @param {*} fileData
 * @returns
 */
export const getImageDataPreview = (fileName = "", fileData: any) => {
  const extension = StringUtils.getExtensionFromFilename(fileName);
  const isImage = IMAGE.extension.includes(extension);
  if (isImage) return fileData;
  const fileFormat = find(FILE_TYPE, (fileTypeItem) =>
    fileTypeItem.extension.includes(extension)
  );
  if (fileFormat) {
    return fileFormat.iconFile;
  }
  return "";
};

export const FileUploadItem = ({
  file,
  onRemove,
  onChange,
  customImageView,
  style,
}: {
  file: IFileItemData;
  onRemove: (props: any) => any;
  onChange: (props: any) => any;
  customImageView?: ((source?: string) => any) | React.ReactElement;
  style?: CSSProperties;
}) => {
  const isPending = file.status === UploadModeStatus.PENDING;
  const isError = file.status === UploadModeStatus.ERROR;
  const isSuccess = file.status === UploadModeStatus.SUCCESS;

  useEffect(() => {
    isPending && onUploadFile();
  }, [file.status]);

  const onRetry = () => {
    onChange && onChange({ ...file, status: UploadModeStatus.PENDING });
  };

  const onUploadFile = () => {
    // const body = new FormData();
    // body.append("file", file.fileData);
    // const body = { file: file?.fileData };
    // API.uploadFile(body)
    //   .then((res) => {
    //     const fileKey = res?.data?.data?.url;
    //     onChange({
    //       ...file,
    //       key: fileKey,
    //       status: UploadModeStatus.SUCCESS,
    //     });
    //   })
    //   .catch((err) => {
    //     onChange({ ...file, status: UploadModeStatus.ERROR });
    //   });
    onChange({
      ...file,
      // key: fileKey,
      status: UploadModeStatus.SUCCESS,
    });
  };

  const classLoading = classNames("file-upload__item-loading", {});

  const classIconSuccess = classNames(
    "btn btn-trans file-upload__item-icon text-success"
  );
  const classIconError = classNames(
    "btn btn-trans file-upload__item-icon text-danger"
  );
  const classIconFooter = classNames(
    "btn btn-trans text-white p-1 flex-center"
  );

  const renderItemFooter = () => {
    if (isPending) return <div />;
    return (
      <div className="file-upload__item-footer">
        <button className={classIconFooter} onClick={() => onRemove(file)}>
          <Icon name="delete" size="small" />
        </button>
        {isError && (
          <button className={classIconFooter} onClick={onRetry}>
            <Icon name="rotate_left" size="small" />
          </button>
        )}
      </div>
    );
  };

  const renderImageView = () => {
    if (customImageView) {
      if (typeof customImageView === "function") {
        return customImageView(file.imageData);
      }
      return customImageView;
    }
    return (
      <img src={file.imageData} className="file-upload__item-image" alt="" />
    );
  };

  return (
    <div className="file-upload__item" style={style}>
      {isSuccess && (
        <button className={classIconSuccess}>
          <Icon name="check_circle" />
        </button>
      )}
      {isError && (
        <button className={classIconError}>
          <Icon name="info" />
        </button>
      )}
      {renderItemFooter()}
      {renderImageView()}
      {isPending && (
        <div className="position-absolute top-0 start-0 end-0 bottom-0 flex-center-y justify-content-center">
          <div className="spinner-border text-white" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};
/**
 *
 * @param {
 * buttonText: customize text in main button
 * disabled: true => disabled button upload
 * onChange: only invoke when file uploaded
 * defaultFiles: Default files before upload, must follow object's format:
 * {
 *    id: 91208391823,
 *    imageData: "uri"
 * }
 * } param0
 * @returns
 */

export interface IButtonFileUploadProps {
  buttonText?: string;
  disabled?: boolean;
  inputParam?: DropzoneOptions;
  className?: string;
  classNameButton?: string;
  maxFiles?: number;
  defaultFiles?: Array<IFileItemData>;
  variant?: "button" | "square";
  mode?: "multi-upload" | "single-upload";
  label?: string;
  error?: any;
  onChange?: (value: IFileItemData[]) => any;
  fileListClassName?: string;
}

export interface IButtonFileUploadMethod {
  refresh: () => any;
}

const ButtonFileUploadRef: React.ForwardRefRenderFunction<
  IButtonFileUploadMethod,
  IButtonFileUploadProps
> = (
  {
    buttonText = "Add Photo",
    disabled = false,
    onChange,
    inputParam = {},
    className = "",
    maxFiles,
    classNameButton = "",
    defaultFiles,
    variant = "button",
    mode = "multi-upload",
    label = null,
    error = null,
    fileListClassName = "",
  },
  ref
) => {
  const isSingleUpload = useMemo(() => mode === "single-upload", [mode]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop as any,
    maxFiles: isSingleUpload ? 1 : undefined,
    maxSize: 10000000,
    onDropRejected,
  });

  // const listDefaultFile = useMemo(
  //     () =>
  //         defaultFiles?.length > 0
  //             ? defaultFiles.map((item) => ({
  //                   ...item,
  //                   status: UploadModeStatus.SUCCESS,
  //               }))
  //             : [],
  //     [defaultFiles]
  // );

  const firstTime = useFirstTime();
  const [listFile, setListFile] = useState<any[]>([]);
  const wrapperClass = classNames(
    { "d-flex align-items-center": variant === "square" },
    className
  );

  useDeepEffect(() => {
    if (defaultFiles && defaultFiles?.length > 0 && firstTime) {
      const listDefaultFile = defaultFiles.map((item) => ({
        ...item,
        status: UploadModeStatus.SUCCESS,
      }));
      setListFile(listDefaultFile);
    }
  }, [defaultFiles]);

  useDeepEffect(() => {
    if (firstTime) return;
    const uploadedFiles = listFile.filter(
      (item) => item.status === UploadModeStatus.SUCCESS
    );
    onChange && onChange(uploadedFiles);
  }, [listFile]);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      setListFile([]);
    },
  }));

  const validateFileLimitNumber = (fileUpload: Array<any>) => {
    if (!!maxFiles && listFile.length + fileUpload.length > maxFiles) {
      Notifications.showError(`Only max ${maxFiles} files!`);
      return true;
    }
    return false;
  };

  /**
   * validate format of file follow inputParam
   * normally, its prevented on mode select but not in drag and drop mode
   * @param {*} fileUpload
   * @returns
   */
  const validateFileType = (fileUpload: Array<any> = []) => {
    if (inputParam?.accept) {
      const acceptTypes: any =
        typeof inputParam?.accept === "string"
          ? split(inputParam?.accept as any, ",")
          : inputParam?.accept;
      const fullAcceptType: string[] = [];
      if (acceptTypes && acceptTypes?.length > 0) {
        acceptTypes.forEach((type: any) => {
          fullAcceptType.push(type);
          const convertType = getType(type);
          if (convertType) {
            fullAcceptType.push(convertType);
          }
        });
      }
      const isUnValidType = some(
        fileUpload,
        (item) => !includes(fullAcceptType, item?.type)
      );
      if (isUnValidType) {
        Notifications.showError(`Only accept ${inputParam?.accept}`);
        return true;
      }
    }
    return false;
  };

  const validateFileInput = (fileUpload: Array<any> = []) => {
    if (validateFileLimitNumber(fileUpload)) {
      return true;
    }
    if (validateFileType(fileUpload)) {
      return true;
    }
    return false;
  };
  function onDrop(fileUpload = []) {
    if (validateFileInput(fileUpload)) {
      return;
    }

    const fileResult: Array<IFileItemData> = [];
    fileUpload.forEach((file: any, index) => {
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        fileResult.push({
          id: StringUtils.getUniqueID(),
          fileData: file,
          imageData: getImageDataPreview(file.name, reader.result),
          status: UploadModeStatus.PENDING,
        });
        if (index === fileUpload.length - 1) {
          const clone = [...fileResult];
          if (isSingleUpload) {
            setListFile(clone as any);
          } else {
            setListFile([...listFile, ...clone] as any);
          }
        }
      };
    });
  }

  function onDropRejected(errors: FileRejection[]) {
    if (errors?.length > 0) {
      errors.forEach((error) => {
        const errorList = error?.errors ?? [];
        if (errorList?.length > 0) {
          errorList.forEach((mess: any) => {
            const errorMess = replace(mess?.message, "10000000 bytes", "10MB");
            Notifications.showError(errorMess);
          });
        }
      });
    }
  }

  const onClickRemoveFile = (item: any) => {
    const listFileResult = listFile.filter((i) => i?.id !== item?.id);
    setListFile(listFileResult);
  };

  const onChangeFile = (file: any) => {
    const listFileResult = ObjectUtils.updateArrayById(listFile as any, file);
    setListFile(listFileResult);
  };

  let button;
  button = (
    <Button className={classNameButton} disabled={disabled} {...getRootProps()}>
      <input {...getInputProps(inputParam as any)} />
      <Icon name="cloud_upload" className="mr-2" />
      {buttonText}
    </Button>
  );

  if (variant === "square") {
    button = (
      <div
        className="border-dashed text-x-small border-primary text-center d-flex align-items-center justify-content-center  hover-pointer"
        style={{
          width: "10rem",
          height: "10rem",
          marginBottom: "1rem",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps(inputParam as any)} />
        <div className="text-center text-primary text">{"Add File"}</div>
      </div>
    );
  }

  const content = (
    <div className={wrapperClass}>
      <div>{button}</div>
      <div className={`${fileListClassName} flex`}>
        {listFile.map((fileItem) => (
          <FileUploadItem
            file={fileItem}
            onChange={onChangeFile}
            onRemove={onClickRemoveFile}
          />
        ))}
      </div>
    </div>
  );

  if (label) {
    return (
      <div>
        <label className="mb-2">{label}</label>
        {content}
      </div>
    );
  }

  return content;
};

const ButtonFileUpload = forwardRef(ButtonFileUploadRef);
export default ButtonFileUpload;
