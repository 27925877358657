import { isEmpty } from "lodash";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../constants/common";
import Navbar from "./Layout/Navbar";

interface ProtectedRouteProps {
  permission?: string;
}

export function ProtectedRoute({
  component: Component,
  permission,
  ...restOfProps
}: ProtectedRouteProps & RouteProps) {
  const [isAuthenticated] = useLocalStorage(ADMIN_AUTH_KEY);

  if (!Component) return null;
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        !isEmpty(isAuthenticated) ? (
          <>
            <Navbar />
            <Component {...props} />
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default ProtectedRoute;
