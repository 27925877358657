import React from "react";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../../constants/common";

const Navbar = () => {
  const [admin, setAdmin, remove] = useLocalStorage<any>(ADMIN_AUTH_KEY);
  const onLogout = () => {
    remove();
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  return (
    <div className="py-2 px-4 bg-white flex items-center shadow-md">
      <div className="flex-1">
        <img src="/favicon.jpg" className="w-12" />
      </div>
      <div className="flex-1 text-right items-end">
        <span className="cursor-pointer" onClick={onLogout}>
          Log out
        </span>
      </div>
    </div>
  );
};

export default Navbar;
